
<template>
<div class="category">
  <el-button type="primary" plain @click="dialogVisible = true"><i class="el-icon-plus"></i> 新增分类</el-button>
  <el-divider></el-divider>
  <el-table :data="tableData" style="width: 100%;" v-loading="loading">
    <el-table-column prop="id" label="ID" width="50"></el-table-column>
    <el-table-column prop="name" label="分类名称"></el-table-column>
    <el-table-column prop="goods_num" label="关联商品数"></el-table-column>
    <el-table-column label="操作" fixed="right" width="200">
      <template #default="scope">
        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">关联商品</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-popconfirm title="确定删除此分类吗？" @confirm="handleDelete(scope.$index, scope.row.id)">
          <el-button size="mini" type="danger" slot="reference">删除</el-button>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination background layout="prev, pager, next, total" page-size = 10 @current-change = "handle_category_current" :current-page = "page" :total="count"></el-pagination>
  <el-dialog
    title="新增分类"
    :visible.sync="dialogVisible"
    width="30%">
    <el-input v-model="name" placeholder="请输入新增分类的名称"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addCategory">确 定</el-button>
    </span>
  </el-dialog>
  <el-dialog title="关联样品到分类" :visible.sync="dialogTableVisible" fullscreen @close = "handleTabClose">
    <el-tabs v-model="activeTabs" @tab-click="handleTabClick">
      <el-tab-pane label="已关联样品" name="first">
        <el-table :data="gridData"  v-loading="gridloading">
          <el-table-column property="image" label="图片">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.image" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column property="title" label="标题"></el-table-column>
          <el-table-column property="price" label="价格"></el-table-column>
          <el-table-column property="stock" label="库存"></el-table-column>
          <el-table-column property="status" label="状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status == 1">已发布</el-tag>
              <el-tag type="info" v-if="scope.row.status == 2">待发布</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleRemoveLink(scope.row)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="未关联样品" name="second">
        <el-table :data="gridData" v-loading="gridloading">
          <el-table-column property="image" label="图片">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.image" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column property="title" label="标题"></el-table-column>
          <el-table-column property="price" label="价格"></el-table-column>
          <el-table-column property="stock" label="库存"></el-table-column>
          <el-table-column property="status" label="状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status == 1">已发布</el-tag>
              <el-tag type="info" v-if="scope.row.status == 2">待发布</el-tag>
            </template>
              </el-table-column>
          <el-table-column label="操作" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAddLink(scope.row)">关联</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-pagination background layout="prev, pager, next, total" page-size = 5 @current-change = "handle_type_current" :current-page = "gridpage" :total="gridcount"></el-pagination>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: 'Category',
  data () {
    return {
      loading: true,
      gridloading: true,
      tableData: [],
      gridData: [],
      dialogVisible: false,
      dialogTableVisible: false,
      name: '',
      activeTabs: 'first',
      page: 1,
      gridPage: 1,
      count: 0,
      gridcount: 0,
      category: '',
      type: 1
    }
  },
  methods: {
    handleEdit (index, row) {
      console.log(index, row)
      this.dialogTableVisible = true
      this.category = row.id
      this.getgoodsdata(row.id, 1, 1)
      this.activeTabs = 'first'
    },
    handleDelete (index, id) {
      var that = this
      this.axios.delete('/admin/goods_category/' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.tableData.splice(index, 1)
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleAddLink (row) {
      var that = this
      this.axios.post('/admin/category_goods', { gid: row.id, category: that.category })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.getgoodsdata(that.category, that.type, that.gridPage)
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleRemoveLink (row) {
      var that = this
      this.axios.delete('/admin/category_goods', {
        params: {
          gid: row.id, category: that.category
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          that.$message({
            message: response.data.msg,
            type: 'success'
          })
          that.getgoodsdata(that.category, that.type, that.gridPage)
        } else {
          that.$message.error('操作失败！')
        }
      })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleTabClick (tab, event) {
      if (tab.name === 'second') {
        this.type = 2
        this.activeTabs = 'second'
      } else {
        this.type = 1
        this.activeTabs = 'first'
      }
      this.getgoodsdata(this.category, this.type, 1)
    },
    handleTabClose () {
      this.getdata(1)
    },
    handle_category_current (e) {
      this.getdata(e)
    },
    handle_type_current (e) {
      this.getgoodsdata(this.category, this.type, e)
    },
    addCategory () {
      var that = this
      this.axios.post('/admin/goods_category', { name: that.name })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.getdata(1)
            that.dialogVisible = false
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // id:分类id,type:关联状态tab,page:当前数据哪一页
    getgoodsdata (id, type, page) {
      var that = this
      that.gridloading = true
      this.axios.get('/admin/goods_category/' + id + '/' + type + '/' + page)
        .then(function (response) {
          if (response.data.code === 0) {
            that.gridData = response.data.list
            that.gridcount = response.data.count
            that.gridpage = response.data.page
          }
          that.gridloading = false
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getdata (page) {
      var that = this
      that.loading = true
      this.axios.get('/admin/goods_category?page=' + page)
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
            that.page = response.data.page
          }
          that.loading = false
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created: function () {
    this.getdata(1)
  }
}
</script>

<style scoped>

</style>
